import * as React from "react";
import { View, Text, Image } from "react-native";

import styles from "../utils/styles.js";

import { FORAGE_ENV } from "@env";

export default class ForageTitle extends React.Component {
  render() {
    return (
      <View style={styles.forageTitleView}>
        <View style={styles.forageTitleRow}>
          <Image
            style={{ width: 84, height: 84 }}
            source={require("../assets/icon_trans_background.png")}
          />
          <Text style={styles.forageTitleText}>Forage</Text>
        </View>
        <View style={styles.forageTitleRow}>
          <Text style={styles.forageSubtext}>Search Food - Get Delivery</Text>
        </View>
      </View>
    );
  }
}
