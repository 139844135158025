import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Image
} from "react-native";

import styles from '../utils/styles';

// https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg
const GoogleLogo = require('../assets/google.png')




const GoogleButton = props => {

  return (
    <TouchableOpacity onPress={props.onPress}>
      <View style={styles.AccountGoogleSigningButtonView}>
        <Image source={GoogleLogo} style={{width: 25, height: 25}}/>
        <View style={{paddingHorizontal: 10}}/>
        <Text style={{fontWeight: "bold", color: "gray"}}>
          {props.title ? props.title : "Sign in with Google"}
        </Text>
      </View>
      </TouchableOpacity>
  );
};



export default GoogleButton;