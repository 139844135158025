import React from "react";
import { 
    View, 
    Button, 
    StyleSheet, 
    TouchableOpacity, 
    Text, 
    Clipboard 
} from "react-native";
// (TODO) Need to fix errors with community clipboard as react-native Clipboard is decprecisated
// import Clipboard from "@react-native-community/clipboard"; 
import styles from "../utils/styles";

export default class PromoTile extends React.Component{
    render() {
        if(!this.props.desc){
            return(<View/>);
        } else {
            return(
                <View style={styles.PromosTileView} key={this.props.codekey}>
                    
                    {/* Promo Description */}
                    <Text style={styles.PromosDescription}>{this.props.desc}</Text>
                    
                    {/* Copy button */}
                    <TouchableOpacity onPress={() => {Clipboard.setString( this.props.code );} }>
                        <Text style={styles.PromosCopyTextButton}>Copy Promotion</Text>
                    </TouchableOpacity>

                </View>
            );
        }

    }

}
