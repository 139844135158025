import React from "react";
import { 
    View,
    Text,
    Linking,
    TouchableOpacity,
} from "react-native";

import styles from "../utils/styles";

export default class ServiceTile extends React.Component{
	constructor(props) {
		super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }

	componentDidUpdate(prevProps, prevState, snapshot){
    }


    render() {
        return(
            <View style={styles.ServiceTileView}>
                <TouchableOpacity>
                    <Text >{this.props.service_name}</Text>
                </TouchableOpacity>

                {/* <Text >
                    {this.props.delivery_fee ? this.props.delivery_fee : "N/A"} /
                    {this.props.service_fee ? this.props.service_fee  : "N/A"} /
                    {this.props.delivery_time ? this.props.delivery_time  : "N/A"}
                </Text> */}

                <Text >
                    {this.props.delivery_fee ? this.props.delivery_fee : "N/A"}
                </Text>

                <Text >
                    {this.props.service_fee ? this.props.service_fee  : "N/A"}
                </Text>

                <Text >
                    {this.props.delivery_time ? this.props.delivery_time  : "N/A"}
                </Text>

            </View>
        );
    }

}