import * as React from 'react';
import { Component } from "react";
import { createStackNavigator } from '@react-navigation/stack';


import LoginScreen from './LoginScreen';
import AccountScreen from './AccountScreen';
import SettingsScreen from './SettingsScreen';
import StackNavHeaderTitle from '../../elements/StackNavHeaderTitle';


export default class AccountNav extends Component  {
  render() {
    const Stack = createStackNavigator();
    return (
      <Stack.Navigator
        initialRouteName="Login"
        screenOptions={{ title: '', headerShown: true,  headerBackTitle:''}}
      >
        <Stack.Screen name="Login" title='' component={LoginScreen}
          options={{ headerTitle: (props) => <StackNavHeaderTitle {...props} /> }}
        />
        <Stack.Screen name="AccountScreen" title='' component={AccountScreen}
          options={{ headerTitle: (props) => <StackNavHeaderTitle {...props} /> }}
        />
        <Stack.Screen name="SettingsScreen" title='' component={SettingsScreen}
          options={{ headerTitle: (props) => <StackNavHeaderTitle {...props} /> }}
        />
      </Stack.Navigator>
    );
  }
}