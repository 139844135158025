import React from "react";
import { 
    View, 
    Text,
    TextInput
} from "react-native";
import { MaterialCommunityIcons } from 'react-native-vector-icons';

import styles from '../utils/styles';

export default class AddressValidator extends React.Component{
	constructor(props) {
		super(props);
        this.state = {
            address_input: "",
        }
    }

    componentDidMount(){
        console.log("Address Validator");
    }

    render() {
        return(
            <View style={styles.AddressValidatorColumnView}>
                {/* <View style={{flex: 0.25, borderWidth: 1, borderColor: 'black'}} >
                    <MaterialCommunityIcons name="map-marker" size={40}/>
                </View> */}
                <TextInput
                    style={styles.AddressValidatorTextInput}
                    multiline={false}
                    // autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="default"
                    returnKeyType="search"
                    placeholder={ this.props.placeholder ? this.props.placeholder : "Enter Location" }
                    onChangeText={ (new_text) => { this.setState({ address_input :  new_text }) } }
                    onSubmitEditing={() => { this.props.onSubmitEditing( this.state.address_input ) } }
                />
            </View>
);
    }
}
