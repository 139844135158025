import * as React from 'react';
import { Component } from "react";
import {
  Text,
  View,
  Image 
} from 'react-native';

import styles from '../utils/styles';
import SearchBar from './SearchBar';
import AddressValidator from './AddressValidator';

export default class Header extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      showAddressValidator: false,
      showSearchBar: false,
    }
  }
  componentDidMount(){
    console.log("Header mounted");
    if(this.props.showAddressValidator != undefined){ this.setState({showAddressValidator: this.props.showAddressValidator}); }
    if(this.props.showSearchBar != undefined){ this.setState({showSearchBar: this.props.showSearchBar}); }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.showAddressValidator){
      if(this.props.showAddressValidator != prevState.showAddressValidator ){
        this.setState({showAddressValidator: this.props.showAddressValidator});
      }
    }
    if(this.props.showAddressValidator){
      if(this.props.showSearchBar != prevState.showSearchBar ){
        this.setState({showSearchBar: this.props.showSearchBar});
      }
    }
  }


  render() {
    return (
      <View style={styles.forageHeaderViewStyle}>

        <View style={{width: 10}} />

        { this.props.showForageLogo ? 
        <View style={{height: 50, flexDirection: 'row', alignItems: 'center',}}>
          <Text style={styles.forageHeaderText} > Forage</Text>

          <Image
            style={{ width: 25, height: 25 }}
            source={require('../assets/icon.png')}
          />

        </View>
          :
        <View/>
        }
        <View style={{paddingRight: 10}}/>


        <View style={{flex: 1, height: 50}} >
          {this.state.showAddressValidator ?
            <AddressValidator
              onSubmitEditing={this.props.address_validator_onSubmitEditing}
            />
            :
            <View/>
          }
        </View>

        <View style={{flex: 1, height: 50}} >
          {this.state.showSearchBar ?
            <SearchBar
              onSubmitEditing={this.props.searchbar_onSubmitEditing}
            />
            :
            <View/>
          }
        </View>

      </View>
    )
  }
}