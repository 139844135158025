import React from "react";
import { 
View, 
Text,
FlatList,
} from "react-native";
import { DataTable } from 'react-native-paper'; 
import styles from "../utils/styles";




export default class CartEstimateList extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
            services: [],
            cart_estimate: 20, // Cart estimation for price calculator. 0 - 100.
        };
        this.ProcessNewServiceList = this.ProcessNewServiceList.bind(this);
        this.StringFormat = this.StringFormat.bind(this);
        this.RenderServiceRow = this.RenderServiceRow.bind(this);
        this.RenderServiceRow = this.RenderServiceRow.bind(this);
        this.SingleValue = this.SingleValue.bind(this);
        this.CalcServiceEst = this.CalcServiceEst.bind(this);
    }

    componentDidMount() {
        console.log("Cart Est. List mounted");
        if (this.props.services) {
            this.ProcessNewServiceList(this.props.services);
        }
        if (this.props.cart_estimate) {
            this.setState({ cart_estimate: this.props.cart_estimate });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.services) {
            if (this.props.services.length != prevProps.services.length) {
                this.ProcessNewServiceList(this.props.services);
            }
        }
        if (this.props.cart_estimate & (this.props.cart_estimate != prevProps.cart_estimate) ) {
            this.setState({ cart_estimate: this.props.cart_estimate });
        }
    }

    ProcessNewServiceList = (services) => {
        // TODO: Need to verify no duplicates exist & if any changes.
        
        // If it is not a list, return.
        if (typeof (services) !== typeof ([])) {
            // Do nothing
        }

        // If just a list of names of services.
        else if (typeof(this.props.services[0]) === typeof ("")) {
            let newServiceList = [];
            for (let item of services) {
                newServiceList.push({
                    service_name: item,
                    delivery_fee: "N/A",
                    delivery_time: "N/A",
                })
            }
            this.setState({ services: newServiceList });
        }

        // Default is to store it as raw data.
        else {
            this.setState({ services: services });
        }
    }

    StringFormat(msg) {
        if (!Boolean(msg)) {
            return "0";
        }
        msg = msg.toLowerCase();
        msg = msg.replace('start at ', '');
        msg = msg.replace('$', '');
        return msg;
    }

    SingleValue(est, val) {
        let retVal = String(val);
        retVal = retVal.replace("$", "");
        retVal = retVal.replace("+", "");
        if ( retVal.includes("%") ) {
            retVal = retVal.replace("%", "");
            retVal = (parseFloat(retVal) / 100.0) * parseFloat(est);
            retVal = String(retVal);
        }
        retVal = parseFloat(est) + parseFloat(retVal);
        return retVal;
    }

    CalcServiceEst(cart_estimate, fee, est_Max = false) {
        let est = 0.0 + parseFloat(cart_estimate);
        if ( !fee.includes('-') ) {
            est = this.SingleValue(est, fee);
        } else {
            let addPercentage = fee.includes('%');
            fee = fee.replace("%", "");
            fee = (est_Max) ? fee.split('-')[1] : fee.split('-')[0];
            fee = (addPercentage) ? fee + "%" : fee;
            est = this.SingleValue(est, fee);
        }
        return est;
    }

    RenderServiceRow(cart_estimate, service, index){
        if (service) {
            let minVal = cart_estimate;
            let maxVal = cart_estimate;
            let service_name = this.StringFormat(service.service_name);
            let delivery_fee = this.StringFormat( String(service.delivery_fee) );
            let service_fee = this.StringFormat( String(service.service_fee) );
            minVal = this.CalcServiceEst(minVal, delivery_fee, false);
            minVal = this.CalcServiceEst(minVal, service_fee, false);
            maxVal = this.CalcServiceEst(maxVal, delivery_fee, true);
            maxVal = this.CalcServiceEst(maxVal, service_fee, true);
            return (
                <DataTable.Row key={ index }>
                    <DataTable.Cell>{ service_name }</DataTable.Cell>
                    <DataTable.Cell>{ "$" + minVal.toFixed(2) }</DataTable.Cell>
                    <DataTable.Cell>{ "$" + maxVal.toFixed(2) }</DataTable.Cell>
                </DataTable.Row>
            );
        }
        return <View/>
    }


    render() {
        if(!this.state.services){
            return(<View/>);
        } else {
            return(
                < View style={styles.CartEstTileView} >
                    <View style={{padding: 5}} />

                    {/* Title */}
                    <View style={styles.CartEstTitleTextViewStyle} >
                        <Text style={styles.CartEstTileTextStyle}>Cart Est. Calculator</Text>
                    </View>

                    <DataTable style={{flex: 1,}}>
                        <DataTable.Header > 
                            <DataTable.Title>Service</DataTable.Title> 
                            <DataTable.Title>Total Est. Min</DataTable.Title>
                            <DataTable.Title>Total Est. Max</DataTable.Title>
                        </DataTable.Header> 

                        <FlatList
                            data={this.state.services}
                            renderItem={({ item, index  }) =>
                                this.RenderServiceRow(this.state.cart_estimate, item, index)}
                            keyExtractor={(item, index) => ( "cart_est_" + item.service_name + String(index) )}
                        />
                    </DataTable> 

                </View>
            );
        }
    }

}