import React from "react";
import { 
    View, 
    Text,
    TextInput
} from "react-native";
import { MaterialCommunityIcons } from 'react-native-vector-icons';

import {FORAGE_ENV} from '@env'

import styles from '../utils/styles';

export default class SearchBar extends React.Component{
	constructor(props) {
		super(props);
        this.state = {
            textinput: "",
        }
    }

    render() {
        return(
            <View style={styles.searchBarView}>
                {/* <View style={{flex: 0.25, borderWidth: 1, borderColor: 'black'}} >
                    <MaterialCommunityIcons name="magnify" size={40}/>
                </View> */}
                <TextInput
                    style={styles.searchBarTextInput}
                    multiline={false}
                    // autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="default"
                    returnKeyType="search"
                    placeholder={ this.props.placeholder ? this.props.placeholder : "Search" }
                    onChangeText={ (inputtext) => { this.setState({ textinput :  inputtext }) } }
                    // onChangeText={ this.props.onChangeText }
                    // onChangeText={() => { this.setState({textinput: this.props.onChangeText })} }
                    // this.setState({ promos :  response.coupons });
                    onSubmitEditing={() => { this.props.onSubmitEditing( this.state.textinput ) } }
                />
            </View>
        );
    }
}
