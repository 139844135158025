import * as React from 'react';
import { Component } from "react";
import { createStackNavigator } from '@react-navigation/stack';


import RestaurantHomeScreen from './RestaurantHomeScreen';
import RestaurantPageScreen from './RestaurantPageScreen';
import RestaurantListScreen from './RestaurantListScreen';
import StackNavHeaderTitle from '../../elements/StackNavHeaderTitle';


export default class RestaurantNav extends Component  {
    render() {
      const Stack = createStackNavigator();
      return (
        <Stack.Navigator
          initialRouteName="Restaurant Home"
          screenOptions={{ title: '', headerShown: true,  headerBackTitle:''}}
        >
          <Stack.Screen name="Restaurant Home" title='' component={RestaurantHomeScreen}
            options={{ headerTitle: (props) => <StackNavHeaderTitle {...props} /> }}
          />
          <Stack.Screen name="Restaurant Page" title='' component={RestaurantPageScreen}
            options={{ headerTitle: (props) => <StackNavHeaderTitle {...props} /> }}
          />
          <Stack.Screen name="Restaurant List" title='' component={RestaurantListScreen}
            options={{ headerTitle: (props) => <StackNavHeaderTitle {...props} /> }}
          />
        </Stack.Navigator>
      );
    }
  }