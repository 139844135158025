import React from "react";
import { 
// Text,
View, 
FlatList,
} from "react-native";
import { DataTable } from 'react-native-paper'; 

import ServiceTile from "./ServiceTile"
var Url = require('url-parse');

import styles from "../utils/styles";




export default class ServiceList extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
            service_list: [],
		};

        this.UpdateServices = this.UpdateServices.bind(this);
        this.StringFormat = this.StringFormat.bind(this);
        this.RenderServiceRow = this.RenderServiceRow.bind(this);
    }

    componentDidMount(){
        if( this.props.service_list ) {
            this.UpdateServices(this.props.service_list);
        };
    }

	componentDidUpdate(prevProps, prevState, snapshot){
        if( this.props.service_list ) {
            this.UpdateServices( this.props.service_list );
        };
    }

    UpdateServices(service_list){
        for(let item of service_list){
            let service_name = item.service_name;
            const found = this.state.service_list.findIndex(el =>
                el.service_name == service_name);
            if (found == -1){
                this.setState({ service_list: [...this.state.service_list, item] });
            }
        }
    }

    StringFormat( msg ) {
        if (!Boolean(msg)) {
            return "N/A";
        }
        msg = msg.toLowerCase();
        msg = msg.replace('start at ', '+');
        return msg;
    }

    RenderServiceRow(service, index){
        if (service) {
            return (
                <DataTable.Row key={ index }>
                    <DataTable.Cell>{ this.StringFormat( service.service_name ) }</DataTable.Cell>
                    <DataTable.Cell>{ this.StringFormat( service.delivery_fee ) }</DataTable.Cell>
                    <DataTable.Cell>{ this.StringFormat( service.service_fee ) }</DataTable.Cell>
                    <DataTable.Cell>{ this.StringFormat( service.delivery_time ) }</DataTable.Cell>
                </DataTable.Row>
            );
        }
        return <View/>
    }

    render() {
        if(!this.props.service_list){
            return(<View/>);
        } else {
            return(
                <View >
                    <View style={{padding: 5}} />

                    <DataTable style={styles.ServicesDataTable}>
                        <DataTable.Header > 
                            <DataTable.Title>Service</DataTable.Title> 
                            <DataTable.Title>Del. Fee</DataTable.Title> 
                            <DataTable.Title>Srv. Fee</DataTable.Title> 
                            <DataTable.Title>Time</DataTable.Title> 
                        </DataTable.Header> 

                        <FlatList
                            data={this.props.service_list}
                            renderItem={({ item, index  }) =>
                                this.RenderServiceRow(item, index)}
                            keyExtractor={(item, index) => index}
                        />
                    </DataTable> 

                </View>
            );
        }

    }

}