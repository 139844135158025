import React from "react";
import { 
    View, 
    Button, 
    StyleSheet, 
    TouchableOpacity, 
    Text,
} from "react-native";
import styles from "../utils/styles";
import ServiceList from "../elements/ServiceList"

export default class RestaurantTile extends React.Component{
    constructor(props) {
        super(props);  
        this.state = {
            restaurantInfo: undefined,
            services: [],
            services_updated_at: undefined,
        }
        this.ProcessNewServiceList = this.ProcessNewServiceList.bind(this);
    }

    componentDidMount(){
        // NOTE: Once Mounted, should receive params passed in from the
        //       RestaurantList Component stored in
        //       this.props.route.params.restaurantInfo
        //       See `screen/RestaurantHomeScreen.js` &
        //       `elements/RestaurantList.js`
        if (this.props.restaurantInfo) {
            this.setState({ restaurantInfo: this.props.restaurantInfo });
        }
        if (this.props.services) {
            this.ProcessNewServiceList(this.props.services);
            // this.setState({ services: this.props.services });
        }
        if (this.props.services_updated_at) {
            this.setState({ services_updated_at: this.props.services_updated_at });
        }
    }

    ProcessNewServiceList = (services) => {
        // TODO: Need to verify no duplicates exist & if any changes.
        
        // If it is not a list, return.
        if (typeof (services) !== typeof ([])) {
            // Do nothing
        }

        // If just a list of names of services.
        else if (typeof(this.props.services[0]) === typeof ("")) {
            let newServiceList = [];
            for (let item of services) {
                newServiceList.push({
                    service_name: item,
                    delivery_fee: "N/A",
                    delivery_time: "N/A",
                })
            }
            this.setState({ services: newServiceList });
        }

        // Default is to store it as raw data.
        else {
            this.setState({ services: services });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.services) {
            if (this.props.services.length != prevProps.services.length) {
                this.ProcessNewServiceList(this.props.services);
            }
        }
    }

    render() {
        return(
            <View style={styles.RestaurantTileView} key={this.props.restaurantKey}>

                {/* Restaurant Image*/}
                {/* <Image 
                    source={ this.props.restaurantInfo.icon ? {uri: this.props.restaurantInfo.icon} : RestaurantPlaceholderimage} 
                    // style={styles.restaurantImageStyle} 
                /> */}

                {/* Restaurant Info*/}
                <Text style={styles.RestaurantTileNameText}>{this.props.restaurantInfo.name}</Text>
                <Text style={{fontWeight: 'bold'}}>{this.props.restaurantInfo.vicinity}</Text>
                <Text style={{ fontWeight: 'bold' }}>{this.props.restaurantInfo.formatted_address}</Text>
                <Text style={{ fontWeight: 'bold'}}>{
                    "⭐️".repeat(this.props.restaurantInfo.rating) +
                    " ".repeat("⭐️".length * (5 - this.props.restaurantInfo.rating)) +
                    "\t" +
                    "＄".repeat(this.props.restaurantInfo.price_level)
                }</Text>

                <View>
                {this.state.services.length ? 
                    <ServiceList 
						restaurantInfo={this.props.restaurantInfo}
						service_list={this.state.services}
						key={this.props.restaurantKey + "_ServiceList"}
						serviceKey={this.props.restaurantKey + "_ServiceList"}
					/>
                    :
                    <View/>
                }
                </View>

                {this.state.services_updated_at ?
                    <Text style={styles.ServiceUpdatedText}>Last Updated: {this.state.services_updated_at}</Text>
                    :
                    <View/>
                }

            </View>
        );
    }
}
