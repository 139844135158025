import * as React from 'react';
import { 
  View, 
  Text, 
  SafeAreaView,
  ScrollView,
} from 'react-native';

import styles from '../../utils/styles.js';
import ForageTitle from '../../elements/ForageTitle.js';

import AccountSwitch from '../../elements/AccountSwitch.js';

export default class SettingsScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <SafeAreaView style={styles.SafeAreaView}>
        <ScrollView style={styles.ScrollViewStyle} contentContainerStyle={{ flex: 1, justifyContent: 'center', alignContent: 'stretch' }}>

          <View>
          <ForageTitle />
          </View>
          

          <View style={{alignItems: 'center', justifyContent: 'center', padding: 10,}}>
            <Text style={styles.forageHeaderText}>Settings</Text>
          </View>
        

          <View style={{ alignContent: 'stretch', paddingTop: 10, paddingBottom: 10 ,}}>
            <AccountSwitch
              icon="email-outline"
              title="Parse Emails"
              beta={ true }
            />
          </View>

          <View style={{ paddingTop: 10, paddingBottom: 10 }}>
          <AccountSwitch
              icon="email-open-outline"
              title="Mark Emails as opened"
              beta={ true }
          />
          </View>

          <View style={{ paddingTop: 10, paddingBottom: 10 }}>
          <AccountSwitch
              icon="account-group-outline"
              title="Share Promo with others"
              beta={ true }
          />
          </View>
        


        </ScrollView>
      </SafeAreaView>
    )
  }

}



