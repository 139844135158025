import * as React from "react";
import { Component } from "react";
import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { MaterialCommunityIcons } from "react-native-vector-icons";
import { LogBox } from "react-native";
import * as Font from "expo-font";

import styles from "./utils/styles";
import styleConst from "./utils/styleConst";
import { FORAGE_ENV } from "@env";

import TemplateScreen from "./screens/Template/TemplateScreen";
import PromosNav from "./screens/Promos/PromosNav";
import RestaurantNav from "./screens/Restaurant/RestaurantNav";
import AccountNav from "./screens/Account/AccountNav";
import TestScreen from "./screens/Test";
import PWDScreen from "./screens/PWDScreen/PWDScreen";
import HomeNav from "./screens/Home/HomeNav";

// Ignore not relevant warnings
LogBox.ignoreLogs([
  "[react-native-gesture-handler] Seems like you're using an old API with gesture components, check out new Gestures system!",
  "Clipboard has been extracted from react-native core and will be removed in a future release. It can now be installed and imported from '@react-native-community/clipboard' instead of 'react-native'. See https://github.com/react-native-clipboard/clipboard",
]);

const Tab = createBottomTabNavigator();

const TabBarOptions = {
  tabBarActiveTintColor: styleConst.forageGreen,
  tabBarInactiveTintColor: styleConst.forageGray,
  showLabel: true,
  headerShown: false,
};

const templateNavOptions = {
  tabBarIcon: ({ color, size }) => (
    <MaterialCommunityIcons name="check-bold" color={color} size={size} />
  ),
};

const PromosNavOptions = {
  tabBarIcon: ({ color, size }) => (
    <MaterialCommunityIcons
      name="ticket-confirmation"
      color={color}
      size={size}
    />
  ),
};

const HomeNavOptions = {
  tabBarIcon: ({ color, size }) => (
    <MaterialCommunityIcons name="home" color={color} size={size} />
  ),
};

const RestaurantNavOptions = {
  tabBarIcon: ({ color, size }) => (
    <MaterialCommunityIcons name="silverware" color={color} size={size} />
  ),
};

const AccountNavOptions = {
  tabBarIcon: ({ color, size }) => (
    <MaterialCommunityIcons name="account" color={color} size={size} />
  ),
};

const TestNavOptions = {
  tabBarIcon: ({ color, size }) => (
    <MaterialCommunityIcons name="test-tube" color={color} size={size} />
  ),
};

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontsLoaded: false,
      isAuthenticated: false,
    };
    this.updateAuthentication = this.updateAuthentication.bind(this);
  }

  async loadFonts() {
    await Font.loadAsync({
      FredokaOne: require("./assets/Fonts/FredokaOne-Regular.ttf"),
    });
    this.setState({ fontsLoaded: true });
  }

  updateAuthentication = (value) => {
    this.setState({ isAuthenticated: value });
  };

  componentDidMount() {
    this.loadFonts();
  }
  render() {
    if (!this.state.fontsLoaded) {
      return null;
    } else {
      return (
        <NavigationContainer>
          {this.state.isAuthenticated ? (
            <Tab.Navigator
              initialRouteName="Home"
              screenOptions={TabBarOptions}
            >
              <Tab.Screen
                name="Home"
                component={HomeNav}
                options={HomeNavOptions}
              />
              <Tab.Screen
                name="Restaurant"
                component={RestaurantNav}
                options={RestaurantNavOptions}
              />
              <Tab.Screen
                name="Account"
                component={AccountNav}
                options={AccountNavOptions}
              />

              {/* <Tab.Screen name="Promotions" component={PromosNav} options={PromosNavOptions} /> */}
              {/*TemplateScreen For Debugging purposes*/}
              {/* <Tab.Screen name="Template" component={TemplateScreen} options={templateNavOptions} /> */}
              {/*Testing Screen API purposes*/}
              {/* < Tab.Screen name={"Test"} component={TestScreen} options={TestNavOptions} /> */}
            </Tab.Navigator>
          ) : (
            <PWDScreen isAuthenticatedFn={this.updateAuthentication} />
          )}
        </NavigationContainer>
      );
    }
  }
}
