import * as React from "react";
import { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Button,
  Alert,
  SafeAreaView,
  ScrollView,
} from "react-native";

import Fire from "../../utils/Firebase";
import styles from "../../utils/styles";

import { FORAGE_ENV } from "@env";

import ForageTitle from "../../elements/ForageTitle";
import PromoList from "../../elements/PromoList";

export default class PromosListScreen extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <SafeAreaView style={styles.SafeAreaViewStyle}>
        <ScrollView style={styles.ScrollViewStyle}>
          <View>
            <ForageTitle />
          </View>

          <PromoList
            PromoListKey={"PromoList" + this.props.route.params.service}
            service={this.props.route.params.service}
          />
        </ScrollView>
      </SafeAreaView>
    );
  }
}
