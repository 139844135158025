import React from "react";
import { 
View, 
Button, 
StyleSheet, 
TouchableOpacity, 
Text, 
Linking
} from "react-native";
import Clipboard from "@react-native-community/clipboard";
import styles from "../utils/styles";

export default class PromoTitle extends React.Component{
	constructor(props) {
		super(props);
    }

    render() {
        return(
            <TouchableOpacity 
                style={styles.PromosTitleView} 
                key={this.props.service} onPress={ () => { this.props.onPress ? this.props.onPress() : Linking.openURL("https://www.forageapp.net/") } }
            >
            {/* Allow when pressed to open the url of the delivery service*/}

                    {/* Promo Title */}
                    <View style={{flex: 1, justifyContent: 'flex-start'}}>
                        <Text style={{color: 'white'}}>
                        <Text style={{fontWeight: 'bold'}}>Service: </Text>
                            <Text>{this.props.service}</Text>
                        </Text>
                    </View>

                    {/* Promo Count */}
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <Text style={{color: 'white'}}>
                            {this.props.count}
                            <Text style={{fontWeight: 'bold', fontSize: 16}}>{' >'}</Text>
                        </Text>
                    </View>

            </TouchableOpacity>
        );
    }

}
