import React from "react";
import { View, TouchableOpacity, Text, } from "react-native";
import { MaterialCommunityIcons } from 'react-native-vector-icons';
import styles from '../utils/styles';
import styleConst from "../utils/styleConst";

// https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg

const AccountTile = props => {
  return(
    <TouchableOpacity
      style={props.color ? [styles.AccountTileView, {backgroundColor: props.color}] : styles.AccountTileView}
      onPress={props.onPress}>
      <View style={{ flexDirection: "row", justifyContent: "flex-start", }}>
        {props.icon ? <MaterialCommunityIcons name={props.icon} size={20} style={{paddingRight: 10}} /> : <View/>}
        <Text style={[styles.itemText, { color: props.fontColor ? props.fontColor : styleConst.forageBlack }]}>
          {props.title ? "  " + props.title : "No title"}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default AccountTile;