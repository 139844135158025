import * as React from "react";
import { Component } from "react";
import { Text, View, } from "react-native";

import styles from "../../utils/styles.js";
import ForageTitle from "../../elements/ForageTitle.js";


export default class PrivacyPolicyScreen extends Component {
  render() {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <View>
          <ForageTitle />
        </View>
        <Text style={styles.forageSubtextPlain}>
          Privacy Policy
        </Text>
      </View>
    );
  }
}
