import React from "react";
import { View, TouchableOpacity, Text, Switch, } from "react-native";
import { MaterialCommunityIcons } from 'react-native-vector-icons';
import styles from '../utils/styles';
import styleConst from "../utils/styleConst";

// https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg

const AccountSwitch = props => {
  return(
    <View>
      <View style={styles.AccountSwitchView}>
        {/* Icon & Text */}
        <View style={{flex: 2, flexDirection: "row", justifyContent: "flex-start", }}>

          {/* Icon */}
          <View style={{justifyContent: 'center', alignItems: 'center', paddingRight: 10, }} >
            {props.icon ?
              <MaterialCommunityIcons name={props.icon} size={15}/> 
              :
              <MaterialCommunityIcons name={"cogs"} size={15}/> 
            }
          </View>

          {/* Text */}
          <Text style={{ textAlign: 'left', fontWeight: 'bold', color: props.fontColor ? props.fontColor : styleConst.forageBlack }}>
            {props.title ? props.title : "No title"}
            {props.beta ? 
              <Text style={{ color: styleConst.forageGreen}}> Beta</Text>
              :
              <Text/>
            }
          </Text>
        </View>

        {/* Switch */}
        <View style={{flex: 1, flexDirection: "row", justifyContent: "flex-end", alignItems: 'center', }}>
          <Switch
            trackColor={{false: styleConst.forageGray, true: styleConst.forageGreen}}
            thumbColor={ props.isEnabled ? '#f5dd4b' : '#f4f3f4'}
            // ios_backgroundColor="#3e3e3e"
            disabled={ props.disabled }
            onValueChange={props.onValueChange} 
            value={props.value}
          />
        </View>

      </View>
    </View>
  
  );
};

export default AccountSwitch;