import * as React from "react";
import { Component } from "react";
import { StyleSheet, Text, View, Button, Alert, TextInput } from "react-native";

import Fire from "../../utils/Firebase";
import styles from "../../utils/styles";
import styleConst from "../../utils/styleConst";
import { FORAGE_ENV } from "@env";
import ForageTitle from '../../elements/ForageTitle.js';
import ForageButton from '../../elements/ForageButton.js';

export default class PWDScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      isAuthenticated: false,
      incorrectPasswordEntered: false,
      websiteKey: "ForageWebsiteKey",
    };
    this.verifyPassword = this.verifyPassword.bind(this);
    this.updateAuthentication = this.updateAuthentication.bind(this);
  }

  componentDidMount() {
    console.log("PWDScreen Mounted");
    Fire.shared.getWebsitePwd().then((key) => {
      if (!Boolean(key)) {
        this.setState({ isAuthenticated: true });
        if (this.props.isAuthenticatedFn) {
          this.props.isAuthenticatedFn(true);
        }
      } else {
        this.setState({ websiteKey: key });
      }
    });
  }

  verifyPassword = async (callbackFn) => {
    if (this.state.password == this.state.websiteKey) {
      if (callbackFn) {
        this.setState({ isAuthenticated: true }, callbackFn(true));
      } else {
        this.setState({ isAuthenticated: true });
      }
    }
    this.setState({ incorrectPasswordEntered: true });
  };

  updateAuthentication = async () => {
    if (this.props.isAuthenticatedFn) {
      await this.verifyPassword(this.props.isAuthenticatedFn);
    } else {
      await this.verifyPassword();
    }
  };

  render() {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <View>
          <ForageTitle />
        </View>
        <View style={{ padding: 5 }} />
        <Text style={{ fontWeight: "bold", textAlign: "center" }}>
          Enter password to view this site
        </Text>
        {this.state.incorrectPasswordEntered ? (
          <Text
            style={{ fontWeight: "bold", textAlign: "center", color: "red" }}
          >
            Incorrect Password
          </Text>
        ) : (
          <View />
        )}
        <View style={{ padding: 5 }} />
        <TextInput
          style={styles.PWDTextInput}
          multiline={false}
          // autoCapitalize="none"
          autoCorrect={false}
          keyboardType="default"
          returnKeyType="search"
          placeholder={
            this.props.placeholder ? this.props.placeholder : "Password"
          }
          onChangeText={(new_text) => {
            this.setState({ password: new_text });
          }}
          onSubmitEditing={this.updateAuthentication}
        />
        <View style={{ padding: 20 }} />
        <ForageButton title="Submit" onPress={this.updateAuthentication} />
      </View>
    );
  }
}
