import React from "react";
import { 
    View, 
    Text,
    TextInput
} from "react-native";
import { MaterialCommunityIcons } from 'react-native-vector-icons';
import Slider from '@react-native-community/slider';

import {FORAGE_ENV} from '@env'

import styles from '../utils/styles';
import styleConst from "../utils/styleConst";

export default class DistanceSlider extends React.Component{
	constructor(props) {
		super(props);
        this.state = {
            value: 10,
            minimumValue: 10,
            maximumValue: 1050,
            title: "Distance",
            units: "meters",
            step: 10,
            minimumTrackTintColor: styleConst.foragePurple,
            maximumTrackTintColor: styleConst.forageGray,
        }
    }

    componentDidMount(){
        console.log("Distance Slider mounted");
        if(this.props.value){ this.setState({value: this.props.value}); }
        if(this.props.minimumValue){ this.setState({minimumValue: this.props.minimumValue}); }
        if(this.props.maximumValue){ this.setState({maximumValue: this.props.maximumValue}); }
        if(this.props.title){ this.setState({title: this.props.title}); }
        if(this.props.units){ this.setState({units: this.props.units}); }
        if(this.props.step){ this.setState({step: this.props.step}); }
        if(this.props.minimumTrackTintColor){ this.setState({minimumTrackTintColor: this.props.minimumTrackTintColor}); }
        if(this.props.maximumTrackTintColor){ this.setState({maximumTrackTintColor: this.props.maximumTrackTintColor}); }
    }

    render() {
        return(
            <View style={styles.DistanceSliderColumnView}>
                <View style={{padding: 5}} />
                {/* Slider title & info */}
                <View style={styles.DistanceSliderTextViewStyle} >
                    <Text style={styles.DistanceSliderInfoTextStyle}>{this.state.title} {this.state.units}({this.state.minimumValue}-{this.state.maximumValue}): </Text>
                    <Text>{this.state.value}</Text>
                </View>

                <View style={{padding: 5}} />

                {/* Slider */}
                <Slider
                    style={styles.DistanceSliderStyle}
                    thumbTintColor={styleConst.foragePurple}
                    minimumValue={this.state.minimumValue}
                    maximumValue={this.state.maximumValue}
                    minimumTrackTintColor={this.state.minimumTrackTintColor}
                    maximumTrackTintColor={this.state.maximumTrackTintColor}
                    step={this.state.step}
                    value={this.state.value}
                    onValueChange={(new_val) => {
                        this.setState({value: new_val});
                        // this.props.onValueChange
                        if(this.props.onValueChange){ this.props.onValueChange(new_val); }
                    }}
                />

                <View style={{ padding: 5 }} />


            </View>
        );
    }
}
