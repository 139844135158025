import React from "react";
import { View, TouchableOpacity, Text } from "react-native";

import styles from "../utils/styles";

const ForageButton = (props) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <View style={styles.ForageButtonView}>
        <Text style={{ fontWeight: "bold", color: "white" }}>
          {props.title ? props.title : "Click Me"}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default ForageButton;
