import React from "react";
import { 
View, 
Button, 
StyleSheet, 
TouchableOpacity, 
Text, 
Image,
ActivityIndicator, 
SafeAreaView,
ScrollView,
Linking,
} from "react-native";
import { MaterialCommunityIcons } from 'react-native-vector-icons';

import PromoTile from "./PromoTile";
import PromoTitle from "./PromoTitle"
import styles from '../utils/styles.js';
import Fire from '../utils/Firebase';


function CreatePromoTile(item, codekey){
	// item Example: 
	// 
    // Object {
    //     "code": "AFF25",
    //     "desc": "25% Off First Order of $15+ (App Only)",
    //     "description": "25% Off First Order of $15+ (App Only)",
    //     "downvote": null,
    //     "expires": null,
    //     "key": "-MlRlXs4oHSAnj3KEng2",
    //     "promo": "AFF25",
    //     "url": "grubhub.com",
    //   }

    return(         
        <View style={styles.PromosTileListView} key={codekey}>   
            <PromoTile
                key={codekey}
                codekey={codekey}
                code={item.code}
                desc={item.desc}
                description={item.description}
                downvote={item.downvote}
                expires={item.expires}
                promo={item.promo}
                url={item.url}
            />
        </View>
    )
}


export default class PromoList extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			promos: null,
			promosCount: 0,
            promosTileList: [],
			service: null,
			url: null,
			maxPromos: null,
			onPress: null,
		};
	}

	componentDidMount(){
        Fire.shared.curator(this.props.service).then((response) => {
            this.setState({ promos :  response.coupons });
            this.setState({ service :  this.props.service });
            // (TODO) Need a more fool proof way to make sure the url will work
			if( !this.props.service.startsWith("https://www.") ){ this.setState({ url :  "https://www." + this.props.service }); }
			else{ this.setState({ url : this.props.service }); }

            this.setState({ maxPromos :  this.props.maxPromos });
            this.setState({ onPress :  this.props.onPress });

            for(let item of response.coupons){
                if( item.desc ){
                    this.setState({ promosTileList: [...this.state.promosTileList, CreatePromoTile(item, "PromoList " + this.state.service + this.state.promosCount) ] })
                    this.setState({ promosCount: this.state.promosCount + 1 })
                }
            }
        })
    }

	componentDidUpdate(prevProps, prevState, snapshot){
	}


    render(){
        return(
            <SafeAreaView key={this.props.PromoListKey} style={styles.SafeAreaViewStyle}>
                <ScrollView key={this.props.PromoListKey} style={styles.ScrollViewStyle}>

                    <View style={styles.PromosTileListView} key={this.state.service}>   
                        <PromoTitle 
                            service={this.state.service} 
                            count={this.state.promosCount}
                            onPress={ () => { this.props.onPress ? this.props.onPress() : Linking.openURL(this.state.url) } }
                        />
                    </View>

					{this.props.maxPromos ? 
						this.state.promosTileList.slice(0, this.props.maxPromos) : 
						this.state.promosTileList 
					}

                </ScrollView>
            </SafeAreaView>
        );
    }

}
