import * as React from 'react';
import { Component } from "react";
import { 
StyleSheet, 
Text, 
View, 
Button, 
Alert, 
SafeAreaView,
ScrollView,
TouchableOpacity,
Linking,
} from 'react-native';

import Fire from '../../utils/Firebase'
import styles from '../../utils/styles';

import {FORAGE_ENV} from '@env'

import SearchBar from '../../elements/SearchBar'
import PromoList from '../../elements/PromoList'
import Header from '../../elements/Header';


export default class PromosHomeScreen extends Component  {
    constructor(props){
        super(props);
    }

    render() {
      return (
            <SafeAreaView style={styles.SafeAreaViewStyle}>

                <Header
                    showAddressValidator={false}
                    showSearchBar={true}
                />

                <ScrollView style={styles.ScrollViewStyle}>

                    {/* <SearchBar
                        placeholder="Search Delivery Service"
                        onSubmitEditing={(textinput) => { 
                            console.log("Search: " + textinput);
                            this.props.navigation.navigate('Promos List', {service: textinput });
                        } }
                    /> */}

                    <PromoList  
                        PromoListKey={"PromosHomeScreen grubhub"} 
                        service={"grubhub.com"} 
                        maxPromos={5} 
                        onPress={() => { this.props.navigation.navigate('Promos List', {service: "grubhub.com"});} } 
                    />

                    <PromoList 
                        PromoListKey={"PromosHomeScreen postmates"} 
                        service={"postmates.com"} 
                        maxPromos={5} 
                        onPress={() => { this.props.navigation.navigate('Promos List', {service: "postmates.com"});} } 
                    />

                    <PromoList  
                        PromoListKey={"PromosHomeScreen ubereats"} 
                        service={"ubereats.com"} 
                        maxPromos={5} 
                        onPress={() => { this.props.navigation.navigate('Promos List', {service: "ubereats.com"});} } 
                    />

                </ScrollView>
            </SafeAreaView>
      )
    }
}