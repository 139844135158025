
/*
Forage Color Guide
Name
    Font: Fredoka One
    Color: #454682 (purple)
Tag Line
    Font: QuickSand
    Color: #454682 (purple)
Icon
    Color 0: #71db77 (green)
    Color 1: #c8c8c8 (gray)
    Color 2: #5c5c5c (black)
*/ 

const styleConst = {
    foragePurple: '#454682',
    forageGreen: '#71db77',
    forageGray: '#c8c8c8',
    forageBlack: '#5c5c5c',
}

export default styleConst;
