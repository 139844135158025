import * as React from "react";
import { Component } from "react";
import { View } from "react-native";

import ForageTitle from "../../elements/ForageTitle.js";
import RestaurantList from "../../elements/RestaurantList.js";

export default class RestaurantListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distance: 0,
      restaurants: [],
      next_page_token: "",
    };
  }

  componentDidMount() {}

  setDistance(newVal) {
    this.setState({ distance: newVal });
  }

  // (TODO) Radius for Forage 0 - 1050 meters
  render() {
    return (
      <View>
        <View>
          <ForageTitle />
        </View>
        <RestaurantList />
      </View>
    );
  }
}
