import * as React from "react";
import { Component } from "react";
import { StyleSheet, Text, View, Button, Alert } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";

import PromosHomeScreen from "./PromosHomeScreen";
import PromosListScreen from "./PromosListScreen";
import StackNavHeaderTitle from "../../elements/StackNavHeaderTitle";

export default class PromosNav extends Component {
  render() {
    const Stack = createStackNavigator();
    return (
      <Stack.Navigator
        initialRouteName="Promos Home"
        screenOptions={{
          title: "Promos",
          headerShown: false,
          headerBackTitle: "",
        }}
      >
        <Stack.Screen
          name="Promos Home"
          title=""
          component={PromosHomeScreen}
          options={{
            headerTitle: (props) => <StackNavHeaderTitle {...props} />,
          }}
        />
        <Stack.Screen
          name="Promos List"
          title=""
          component={PromosListScreen}
          options={{
            headerTitle: (props) => <StackNavHeaderTitle {...props} />,
          }}
        />
      </Stack.Navigator>
    );
  }
}
