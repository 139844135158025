import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Platform, Alert } from "react-native";
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
} from "firebase/auth";

import { FORAGE_ENV } from "@env";

import { apiKey } from "@env";
import { authDomain } from "@env";
import { databaseURL } from "@env";
import { projectId } from "@env";
import { storageBucket } from "@env";
import { messagingSenderId } from "@env";
import { appId } from "@env";
import { measurementId } from "@env";

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  databaseURL: databaseURL,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId,
};

class Fire {
  constructor() {
    // Initialize Firebase
    var app = undefined;
    try {
      app = firebase.app("ForageApp");
    } catch (e) {
      // Forage App is uninitialized
      app = firebase.initializeApp(firebaseConfig, "ForageApp");
    }

    this.firebaseConfig = firebaseConfig;
    this.app = app;
    this.platform = Platform.OS;

    this.auth = firebase.auth(this.app);
    // this.auth = getAuth(firebaseConfig);
    // this.database = firebase.database( this.app );
    // this.auth = getAuth(firebaseConfig);
    this.firestoreDB = getFirestore(this.app);
    this.auth.languageCode = this.auth.useDeviceLanguage();
    this.provider = new GoogleAuthProvider();
    // this.provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    this.functions = firebase.functions(this.app);

    console.log("FIREBASE WAS CREATED w/ FORAGE_ENV:  " + FORAGE_ENV);
    this.FORAGE_ENV = FORAGE_ENV;
    if (FORAGE_ENV == "test") {
      this.FORAGE_ENV = "test";
    } else if (FORAGE_ENV == "prod") {
      this.FORAGE_ENV = "production";
    }
  }

  tester = async () => {
    let tester = "tester-";
    console.log(tester + this.FORAGE_ENV);
    let testerFn = this.functions.httpsCallable("tester-" + this.FORAGE_ENV);

    testerFn()
      .then((response) => {
        console.log(response);
        // console.log( response.data );
      })
      .catch((err) => {
        console.log("Error @ tester: " + err);
      });
  };

  curator = async (service) => {
    let curator = "promos-curator-" + this.FORAGE_ENV;
    console.log(curator);
    let curatorFn = this.functions.httpsCallable(curator);

    return new Promise((resolve) => {
      curatorFn({ service: service })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log("Error @ curator: " + err);
          resolve();
        });
    });
  };

  restaurant_search = async (
    lat,
    long,
    rad,
    page_token = undefined,
    query = undefined
  ) => {
    /* 
        (NOTE): Restaurant Search Back End API
            Required:
                latitude: location lat
                longitude: location long
                radius: location radius (meters)
            Optional:
                page_token: page token to get the next page
                query: string query to search with
        */

    let restaurant = "restaurant-search-" + this.FORAGE_ENV;
    console.log(restaurant);
    let restaurantFn = this.functions.httpsCallable(restaurant);

    let data = { latitude: lat, longitude: long, radius: rad };
    if (page_token) {
      data.page_token = page_token;
    }
    if (query) {
      data.query = query;
    }
    console.log(data);

    return new Promise((resolve) => {
      if (lat == undefined || long == undefined) {
        resolve({ results: [], next_page_token: "" });
      } else {
        restaurantFn(data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            console.log("Error @ restaurant_search: " + err);
            resolve({ results: [], next_page_token: "" });
          });
      }
    });
  };

  google_signin_with_popup = async () => {
    console.log("google_signin_with_popup() pressed...");
    return new Promise((resolve) => {
      if (this.auth.currentUser != null) {
        console.log("google_signin_with_popup() user is already logged in");
        resolve();
      } else {
        signInWithPopup(this.auth, this.provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // IdP data available using getAdditionalUserInfo(result)
            // ...
            console.log(
              "google_signin_with_popup() finished with signInWithPopup()..."
            );
            resolve();
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            console.log(
              "google_signin_with_popup() finished with error code(" +
                errorCode +
                ") " +
                "message(" +
                errorMessage +
                ") credential(" +
                credential +
                ")"
            );
            resolve();
          });
      }
    });
  };

  signout = async () => {
    console.log("firebase.signout().");
    this.auth.signOut();
  };

  isNewUser = async () => {
    // Check if the user is logged in. Else check the time of last login
    if (this.auth.currentUser) {
      const isNewUser =
        this.auth.currentUser.metadata.creationTime ===
        this.auth.currentUser.metadata.lastSignInTime;
      console.log("Firebase Is New User: " + isNewUser);
      return isNewUser;
    } else {
      return false;
    }
  };

  userSetup = async () => {
    console.log("Firebase.userSetup().");
    // // Source: https://firebase.google.com/docs/reference/js/firebase.database.DataSnapshot
    // // Source: https://firebase.google.com/docs/reference/js/firestore_
    // if( this.auth.currentUser ) { // check that the user is signed in
    // 	var uid = this.auth.currentUser.uid;

    // 	await this.database.ref('settings/' + uid).set({
    // 		email_process: true,
    // 		email_mark_read: true,
    //         promo_share_public: true,
    // 	});

    // } else {
    // 	Alert.alert( "ForageApp", "User is not signed in");
    // 	console.log("User is not signed in");
    // }
  };

  restaurant_delivery_provider_search = async (
    place_id,
    uch = false,
    usrp = true,
    casf = true
  ) => {
    let delivery = "restaurant-delivery-provider-search-";
    // console.log( delivery + this.FORAGE_ENV + " @ place_id: " + place_id );
    let deliveryFn = this.functions.httpsCallable(delivery + this.FORAGE_ENV);
    // let data = {place_id: place_id, uch: uch, usrp: usrp};
    let data = { place_id: place_id };
    return new Promise((resolve) => {
      deliveryFn(data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log("Error @ delivery_provider_search: " + err);
          resolve({ services: [] });
        });
    });
  };

  restaurant_delivery_fees = async (
    lat,
    long,
    rad,
    page_token = undefined,
    query = undefined
  ) => {
    /*
     */
    let fees_fn_name = "restaurant-delivery-fees-" + this.FORAGE_ENV;
    let feesFn = this.functions.httpsCallable(fees_fn_name);
    console.log(fees_fn_name);

    let data = { latitude: lat, longitude: long, radius: rad, wfs: true };
    if (page_token) {
      data.token = page_token;
    }
    // if(query){ data.query = query }

    return new Promise((resolve) => {
      feesFn(data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log("Error @ restaurant_delivery_fees: " + err);
          resolve({ results: [] });
        });
    });
  };

  address_validation = async (
    addressLines,
    regionCode = "US",
    locality = ""
  ) => {
    /*
        Sorces:
            - https://developers.google.com/maps/documentation/address-validation
        */
    console.log("address_validation");
    let URL =
      "https://addressvalidation.googleapis.com/v1:validateAddress?key=" +
      firebaseConfig.apiKey;

    var data = {
      address: {
        regionCode: regionCode,
        locality: locality,
        addressLines: [addressLines],
      },
    };
    var form = JSON.stringify(data);

    return new Promise((resolve) => {
      fetch(URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: form,
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response.result);
          resolve(response.result);
        })
        .catch((error) => {
          console.error(error);
          resolve({});
        });
    });
  };

  getWebsitePwd = async () => {
    let fn_name = "getWebsitePwd-" + this.FORAGE_ENV;
    console.log(fn_name);
    const docRef = doc(this.firestoreDB, "websitePwd", "pwd");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const retPwd = docSnap.data().pwd;
      return retPwd;
    } else {
      console.log("website pwd does not exist.");
      return "";
    }
  };
}

Fire.shared = new Fire();
export default Fire;
