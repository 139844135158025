import * as React from 'react';
import { Component } from "react";
import { 
StyleSheet, 
Text, 
View, 
Button, 
Alert, 
SafeAreaView,
ScrollView,
TouchableOpacity,
Linking,
} from 'react-native';

import Fire from '../../utils/Firebase'
import styles from '../../utils/styles';

import RestaurantList from '../../elements/RestaurantList'
import DistanceSlider from '../../elements/DistanceSlider';
import Header from '../../elements/Header'


const DEFAULT_ADDRESS = "Federal Hill-Montgomery Baltimore, MD";
// const DEFAULT_ADDRESS = "4570 S Hualapai Wy, Las Vegas, NV 89147";

export default class RestaurantHomeScreen extends Component  {
    constructor(props){
        super(props);
        this.state = {
            
            // distance is in range from 0 - 50,000 meters for BackEnd APIs
            // using the 1600 - 8000 i.e. 1 - 5 miles
            distance: 8000,
            cart_estimate: 4, // Price Range filter 1 - 4
            cur_address: DEFAULT_ADDRESS,
            latitude: undefined,
            longitude: undefined,
            query: undefined,
        }
        this.UpdateAddress = this.UpdateAddress.bind(this);
        this.UpdateRestaurantQuery = this.UpdateRestaurantQuery.bind(this);
    }


    componentDidMount(){
        console.log("RestaurantHomeScreen Mounted");
        this.UpdateAddress(DEFAULT_ADDRESS);
    }



    UpdateAddress(address){
        Fire.shared.address_validation(address)
        .then(result => {
            console.log("Update Address: ");
            if(result.geocode && result.address){
                console.log("Update Address to " + result.address.formattedAddress);
                this.setState({
                    latitude: result.geocode.location.latitude,
                    longitude: result.geocode.location.longitude,
                    cur_address: result.address.formattedAddress
                })
            }
        })
    }


    UpdateRestaurantQuery(new_query){
        this.setState({query: new_query});
    }

    render() {
        return (
            <SafeAreaView style={styles.SafeAreaViewStyle}>

                <Header
                    showForageLogo={ false }
                    showAddressValidator={true}
                    showSearchBar={true}
                    address_validator_onSubmitEditing={this.UpdateAddress}
                    searchbar_onSubmitEditing={this.UpdateRestaurantQuery}
                />


                <ScrollView style={styles.ScrollViewStyle} contentContainerStyle={{ justifyContent: 'center' }}>

                    {/* <Adsense/> */}

                    {/* <View style={{padding: 10}} /> */}
                    <Text>Current Address: {this.state.cur_address}</Text>
                    <Text>Current Lat: {this.state.latitude}</Text>
                    <Text>Current Log: {this.state.longitude}</Text>

                    <View style={styles.DistanceSliderRestaurantHomeView}>
                        <DistanceSlider
                                value={this.state.cart_estimate}
                                minimumValue={1}
                                maximumValue={4}
                                title={"Max Price Filter"}
                                units={"$"}
                                step={1}
                                onValueChange={(new_estimate) => { this.setState({cart_estimate: new_estimate})}}
                            />
                    </View>

                    <RestaurantList
                        cart_estimate={this.state.cart_estimate}
                        latitude={this.state.latitude}
                        longitude={this.state.longitude}
                        distance={this.state.distance}
                        query={this.state.query}
                        onPress={(params) => { this.props.navigation.navigate('Restaurant Page', params); }}
                    />

                </ScrollView>
            </SafeAreaView>
        )
    }
}